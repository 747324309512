aside .t-wrapper {
  width: 100% !important;
  max-width: 40rem !important;
}

.h-funnels {
  aside.t-right {
    .t-wrapper[tuiactivezone] {
      width: calc(100% - 40rem) !important;
      max-width: calc(100% - 40rem) !important;

      @media (max-width: 1200px) {
        width: 100vw !important;
        max-width: 100vw !important;
      }

      tui-text-area {
        .t-wrapper {
          max-width: none !important;
        }
      }
    }
  }

  tui-portal-host {
    z-index: unset;

    tui-dialog-host {
      z-index: 1;
    }
  }
}
