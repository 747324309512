@import 'config';

// show df-filter-list-sub-header as dialog on mobile
@media (max-width: $sm) {
  df-base-panel {
    df-filter-list-sub-header {
      display: none !important;
    }
  }
}
