tui-accordion {
  ._has-arrow {
    .header {
      padding: 0.75rem 1.5rem !important;
    }
  }

  tui-expand {
    > .wrapper > .content {
      padding: 0px 1.5rem 1rem 1.5rem !important;
    }
  }

  [data-tui-host-size='s'] {
    .header {
      padding: 0rem 1rem 0rem 1rem !important;
    }
  }
}
