@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

// fonts for generating PDF

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}
