tui-push-alert {
  position: relative;
  z-index: 99999999;

  tui-push {
    border: 1px solid #52c41a;
    background-color: #eef9e8 !important;
    max-width: 500px !important;
    width: auto !important;
  }

  .t-top {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #191c30e5 !important;
  }

  .t-link button {
    text-decoration: underline;
    color: #4d69ff;
    font-weight: 500 !important;
    font-size: 14px;
  }

  .t-content {
    display: block !important;
    div {
      font-weight: 400;
      font-size: 14px;
      color: #191c30e5;
    }
  }
}
