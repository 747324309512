$full: 1920px;
$xxl: 1535px;
$xl: 1279px;
$lg: 1023px;
$md: 767px;
$sm: 639px;

$white: #fff;
$black: #000;

$h-header: 60px;
$h-subheader: 50px;
$w-left-sidebar: 264px;
$w-left-lg-sidebar: 180px;
$w-left-sm-sidebar: 60px;
$w-left-activities: 56vw;
$w-left-canvases: 220px;
$w-left-xl-sidebar: 275px;
$w-left-xxl-sidebar: 380px;
$w-left-xxxl-sidebar: 440px;

$tactic-tile-min-width: 210px;
