@import 'config';

button[tuibutton],
button[tuiiconbutton] {
  &[data-size='xl'] {
    .t-wrapper {
      padding: 0 1.5rem !important;
    }
  }

  &.current-color-fill {
    svg > path {
      fill: currentColor !important;
    }
  }

  [tuiWrapper] {
    .t-content {
      font: var(--tui-font-text-l);
    }

    &._focused:after {
      border-width: 0 !important;
    }

    &[data-appearance='primary'] {
      background: var(--tui-primary);
      color: var(--tui-primary-text);

      svg {
        path {
          fill: var(--tui-primary-text);
        }
      }

      &[data-state='hovered'] {
        background: var(--tui-accent-03);
      }

      &[data-state='pressed'] {
        background: var(--tui-accent-04);
      }
    }

    &[data-appearance='secondary'] {
      background: var(--tui-secondary);
      color: var(--tui-primary);

      svg {
        path {
          fill: var(--tui-primary);
        }
      }

      &[data-state='hovered'] {
        background: var(--tui-primary);
        color: var(--tui-primary-text);
      }

      &[data-state='pressed'] {
        background: var(--tui-accent-04);
        color: var(--tui-primary-text);
      }
    }

    &[data-appearance='outline'] {
      color: var(--tui-text-03);

      svg {
        path {
          fill: var(--tui-text-03);
        }
      }

      &:after,
      &._focused:after {
        transition: color var(--tui-duration) ease-in-out;
        border-width: 1px !important;
        color: var(--tui-base-04);
      }

      &[data-state='hovered'] {
        background: var(--tui-accent-08);
      }
    }

    &[data-appearance='flat'] {
      &[data-state='hovered'] {
        background: var(--tui-base-03);
      }
    }

    &[data-appearance='accent'] {
      background-color: var(--tui-accent-13);

      .t-content {
        font: var(--tui-font-text-l);
        color: var(--tui-primary) !important;
        font-weight: 500;
      }

      svg {
        path {
          fill: var(--tui-primary);
        }
      }

      &:hover {
        background-color: var(--tui-accent-13-hover);
      }
    }

    &[data-state='disabled'] {
      opacity: 0.6;
    }
  }
}

button.option-checkbox {
  [tuiWrapper] {
    border-radius: 100%;
    position: relative;

    .mark {
      display: none;
    }

    & > span:before {
      display: block;
      background-color: white;
      content: '';
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      transition-duration: 300ms;
    }

    &[data-appearance='primary'] > span:before {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
}

@media (max-width: $md) {
  button {
    &[data-size='l'] {
      [tuiWrapper] {
        padding: 0 1rem !important;
      }
    }
  }
}
