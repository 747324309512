$background-mask-opacity: 0.7;
$box-shadow-opacity: 0.75;

body.onboarding-running {
  df-base-left-side-navigation,
  .base-panel-content__header,
  .menu-burger,
  df-header-tabs {
    //overflow: hidden;
    //pointer-events: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, $background-mask-opacity);
    }
  }

  * {
    pointer-events: none !important;
  }

  .weglot-container,
  .weglot-container *,
  .onboarding-active-events,
  .onboarding-active-events *,
  .onboarding-action,
  .onboarding-help-mark-anchor {
    pointer-events: auto !important;
  }

  .onboarding-fade {
    animation: fadeIn 400ms ease-in 0ms 1;
  }

  $spread: 2000px;

  .onboarding-focus {
    z-index: 9999;

    &.onboarding-shadow {
      box-shadow: 5px 20px 40px rgba(184, 183, 208, 0.35);
    }

    &:not(.onboarding-next) {
      &::before {
        -webkit-box-shadow: 0 0 $spread $spread rgba(233, 233, 233, $box-shadow-opacity);
        -moz-box-shadow: 0 0 $spread $spread rgba(233, 233, 233, $box-shadow-opacity);
        box-shadow: 0 0 $spread $spread rgba(233, 233, 233, $box-shadow-opacity);
        display: block;
        content: '';
        position: fixed;
      }

      & > * {
        position: relative;
      }
    }

    &.onboarding-action,
    &.onboarding-action * {
      pointer-events: auto !important;
    }
  }

  .onboarding-focus-fullscreen-extra {
    display: none;
    // position: absolute;
    bottom: 100%;
    transform-origin: bottom left;
  }

  .onboarding-focus-fullscreen-container {
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto !important;
    transition-duration: 300ms;
    max-height: 100vh;
    overflow-y: scroll;

    .onboarding-focus-fullscreen-extra {
      display: block;
    }
  }

  .onboarding-focus-fullscreen {
    opacity: 0;
    transform-origin: center center;
    pointer-events: auto !important;
    position: relative;
  }

  .onboarding-accent {
    box-shadow:
      0 0 0 8px rgba(240, 4, 127, 0.45),
      0 16px 24px rgba(187, 62, 127, 0.68);
    border-radius: 0.75rem;
    position: relative;

    &__label {
      position: absolute;
      text-align: center;
      font-size: 17px;
      line-height: 1.15;
      font-weight: 700;
      color: var(--tui-base-01);
      background: linear-gradient(114.44deg, #ef1286 0%, #fc6768 100%);
      border-radius: 0.5rem;
      padding: 0.5rem;
      z-index: 99999;
      width: max-content;
    }

    &-parent {
      position: relative;
      z-index: 99999;
    }

    &.pulse:not(.cdk-drag-placeholder) {
      animation: accentPulse 800ms ease-in-out 500ms infinite;
    }
  }

  .onboarding-tooltip {
    position: absolute;
    background-color: var(--tui-primary);
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    color: var(--tui-base-01);
    z-index: 99999;
    width: auto;
    animation: slideIn 400ms ease-in 0ms 1;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-bottom: 0.75rem solid var(--tui-primary);
      position: absolute;
      bottom: 100%;
      left: 1rem;
    }

    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }

    &__subtitle {
      font: var(--tui-font-text-s);
      font-weight: 600;
    }
  }

  .onboarding-arrow {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 99999;
    animation: slideInToBottom 400ms ease-in 0ms 1;
    display: none;

    @media (min-width: $md) {
      display: block;
    }
  }

  .onboarding-help-mark-anchor {
    position: relative;
  }

  .onboarding-help-mark {
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 100%;
    animation: slideInToBottom 500ms ease-in 0s 1;
    cursor: pointer;
    position: absolute;
    top: -1rem;
    right: 0.5rem;
    pointer-events: auto !important;
    transition: transform 0ms;

    @media (min-width: $lg) {
      right: -0.5rem;
    }

    .onboarding-help-mark__anchor {
      width: 100%;
      height: 100%;
      border: 1px solid var(--tui-accent);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--tui-accent);
      border-radius: 100%;
      background-color: var(--tui-base-01);
      box-shadow: 0 4.72509px 9.45019px rgba(250, 68, 144, 0.36);
      transition-duration: 200ms;
    }

    &:hover {
      .onboarding-help-mark__anchor {
        transform: rotateZ(-25deg);
      }
    }
  }

  .onboarding-help-mark__tooltip {
    color: var(--tui-base-01);
    transform: scale(0);
    transform-origin: top right;
    transition: transform 200ms;
    position: fixed;
    padding: 0.75rem;
    background: linear-gradient(200deg, #ef1286 0%, #fc6768 100%);
    border-radius: 0.75rem;
    width: auto;
    min-width: 180px;
    box-shadow: 0 4.72509px 9.45019px rgba(250, 68, 144, 0.36);
    z-index: 9999999;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid var(--tui-accent);
      position: absolute;
      bottom: 100%;
      right: 0.65rem;
    }

    .onboarding-help-mark__title {
      font: var(--tui-font-text-xl);
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .onboarding-help-mark__text {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 600;
    }
  }

  .onboarding-accent-shadow {
    box-shadow: 0px 0px 34px rgba(240, 4, 127, 0.3);
  }

  .gt-container__steps {
    pointer-events: none !important;
    tui-tabs {
      pointer-events: none !important;
    }
  }
}

@keyframes popInLeft {
  from {
    transform: scale(0) rotate(-120deg);
  }

  to {
    transform: scale(1) rotate(-4deg);
  }
}

@keyframes popInRight {
  from {
    transform: scale(0) rotate(-120deg);
  }

  to {
    transform: scale(1) rotate(4deg);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-2rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInToBottom {
  from {
    transform: translateY(2rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes accentPulse {
  from {
    box-shadow:
      0 0 0 8px rgba(240, 4, 127, 0.45),
      0 16px 24px rgba(187, 62, 127, 0.68);
  }

  to {
    box-shadow:
      0 0 0 12px rgba(240, 4, 127, 0.2),
      0 16px 24px rgba(187, 62, 127, 0.4);
  }
}
