tui-toggle {
  &[data-size='l'] {
    width: 2.5rem !important;

    [tuiWrapper] {
      .toggle {
        width: 3.5rem !important;
        transform: translateX(-1rem) !important;
      }
    }
  }

  [tuiWrapper] {
    background: var(--tui-base-02);

    &:after {
      color: var(--tui-text-04) !important;
    }

    .toggle {
      .circle {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &._checked {
    [tuiWrapper] {
      background: var(--tui-primary);

      .toggle {
        transform: translateX(0rem) !important;
      }

      &:after {
        color: var(--tui-primary) !important;
      }
    }
  }
}
