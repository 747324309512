.tui-text_body-m-1 {
  font: var(--tui-font-text-l);
  font-weight: 600;
}

.tui-text_body-m-3 {
  font: var(--tui-font-text-l);
}

.tui-text_body-m-4 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.tui-text_body-m-6 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.tui-text_body-m-7 {
  font-size: 0.938rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.tui-text_body-m-8 {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
}

.tui-text_body-m-9 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.tui-text_body-m-10 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
}

.tui-text_body-m-11 {
  font-size: 0.813rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.tui-text_body-m-12 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
}

.tui-text_body-m-13 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.tui-text_body-m-14 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.tui-text_body-m-15 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.tui-text_body-m-16 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.tui-text_body-17 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
}

.tui-text_body-18 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
}

.tui-text-body-s-2 {
  font: var(--tui-font-text-s);
}

.tui-text-body-xs {
  font: var(--tui-font-text-xs);
}

.tui-text-body-xs-2 {
  font: var(--tui-font-text-xs) !important;
}

.labels {
  font: var(--tui-font-text-s);
}

.tui-text_menu-header {
  font: var(--tui-font-heading-7) !important;
  text-decoration: none;
}
