.h-notification-success {
  tui-portal-host {
    z-index: unset;
  }

  tui-notifications-host {
    top: auto;
    bottom: 90px;

    > .wrapper {
      @media screen and (min-width: 85em) {
        width: 11.125rem;
      }
    }
  }

  tui-notification {
    padding: 0.5rem 1rem !important;
    border-radius: 1.5rem !important;
    tui-svg {
      display: none !important;
    }

    &[data-tui-host-status='success']:after {
      background: #202020;
    }

    .content {
      [automation-id='tui-notification-alert__content'] {
        text-align: center;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        position: relative;
        z-index: 12;

        &::after {
          content: '😎';
        }
      }
    }

    [automation-id='tui-notification__close'] {
      display: none !important;
    }
  }
}
