@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './assets/scss/core';
@import './assets/scss/custom/custom';
@import './assets/scss/custom/kompassify-notif-widget.scss';
@import '@taiga-ui/core/styles/taiga-ui-local';
@import 'swiper/css';
@import 'swiper/css/pagination';

@import 'config';

.kompassify-progress-player-frame {
  display: none !important;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

// Notification list
.kompassify-announcement-player-frame {
  inset: 350px 0 auto auto !important;

  .annoucement-list-container {
    max-height: 57vh !important;
  }
}

//intercome
.intercom-lightweight-app-launcher {
  position: fixed !important;
  right: 100px !important;
  bottom: 40px !important;
}
