tui-filter {
  [data-appearance='whiteblock'] {
    background: var(--tui-base-01);
    border: solid 1px var(--tui-base-04);
  }

  [data-appearance='whiteblock-active'] {
    background: var(--tui-accent-08);
    border: solid 1px var(--tui-primary);

    .content {
      color: var(--tui-primary);
    }
  }

  [data-size='l'] {
    .content {
      padding: 0 0.7rem !important;
      font: var(--tui-font-text-s);
    }
  }
}
