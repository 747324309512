tui-select {
  [data-size='l'] {
    height: 3rem !important;
    min-height: 3rem !important;
    max-height: 3rem !important;
    font: var(--tui-font-text-m) !important;

    .t-placeholder {
      font: var(--tui-font-text-m) !important;
      height: 1.5rem !important;

      &_raised {
        transform: translateY(-0.625rem) translateX(-1.5rem) scale(0.85) !important;
      }
    }

    .absolute-wrapper,
    .t-icons {
      height: 3rem !important;
    }

    [tuiWrapper] > .content {
      padding-left: 1rem !important;
    }

    tui-hosted-dropdown {
      height: inherit;
    }
  }

  .t-icons {
    .t-icon {
      color: var(--tui-text-03) !important;
    }
  }

  &._readonly {
    [tuiWrapper] {
      opacity: 0.7 !important;

      &:after {
        color: var(--tui-base-04) !important;
      }
    }
    .t-icons {
      display: none !important;
    }
  }
}
