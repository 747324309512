.jtk-connector {
  z-index: 30;

  path {
    stroke-width: 2;
    stroke: var(--tui-primary) !important;
  }

  &.remove {
    path {
      stroke: var(--tui-accent-01) !important;
    }
  }
}

.jtk-endpoint {
  width: 0.5rem !important;
  height: 0.5rem !important;
  border-radius: 2rem;
  background-color: var(--tui-primary);
  z-index: 35;

  svg {
    circle {
      fill: var(--tui-primary);
    }
  }
}

.jtk-overlay {
  z-index: 60;
}

.removeLabel {
  padding: 0.5rem;
  background-color: var(--tui-base-01);
  border-radius: var(--tui-radius-xs);
  color: var(--tui-accent-01);
  font: var(--tui-font-text-xs);
  box-shadow:
    0px 2px 6px rgba(207, 215, 224, 0.79),
    0px 1px 1px #d3d9de;
  pointer-events: none;
}
