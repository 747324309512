.df-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 1.25rem 2.25rem;
  background-color: var(--tui-base-02);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @media (max-width: $lg) {
    padding: 1.25rem 1rem;
  }

  @media (max-width: $sm) {
    padding: 0.5rem;
  }

  &-content {
    background-color: var(--tui-base-01);
    border-radius: 1.5rem;
    flex-grow: 1;
  }
}

.df-single-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 3.5rem 1rem 2rem;
  margin: 0 auto;
  max-width: $sm;
  position: relative;

  @media (max-width: $lg) {
    padding: 2rem 1rem;
  }

  @media (max-width: $sm) {
    padding: 1rem 1rem;
  }
}

.df-two-columns {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: $lg) {
    flex-wrap: unset;
    flex-direction: column;
    justify-content: flex-start;
  }

  &:not(.separate) {
    .df-column:first-child {
      width: 485px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 1rem 1.25rem;

      @media (max-width: $xl) {
        width: 320px;
      }

      @media (max-width: $lg) {
        width: 100%;
        padding: 1rem;
      }
    }

    .df-column:nth-child(2) {
      flex-grow: 1;
      padding: 1rem 1.5rem;
      border-left: 1px solid var(--tui-base-04);

      @media (max-width: $lg) {
        flex-grow: unset;
        padding: 1rem;
        border-left: 0;
      }
    }
  }

  &.separate {
    flex-grow: 1;
    justify-content: flex-start;
    column-gap: 1.5rem;
    row-gap: 1rem;

    .df-column:first-child {
      flex-shrink: 0;
      flex-grow: 0;
      padding: 0;
      width: 55%;

      @media (max-width: $lg) {
        width: unset;
      }
    }

    .df-column:nth-child(2) {
      flex-grow: 1;
      padding: 0;

      @media (min-width: $md) and (max-width: $lg) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        grid-template-rows: min-content min-content;

        & > *:nth-child(3) {
          grid-column: 1 / span 2;
        }
      }
    }
  }
}

.df-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem;
  overflow: hidden;
}

.df-column-title {
  font: var(--tui-font-text-l);
  font-weight: 600;
  color: var(--tui-text-03);
  margin-bottom: 1.5rem;
}

.df-tile {
  border-radius: 1rem;
  background-color: var(--tui-base-01);
  border: 1px solid var(--tui-base-04);
  padding: 1.5rem;
  position: relative;

  @media (max-width: $lg) {
    padding: 1rem;
  }

  .df-column-title {
    font-size: 1.375rem;
    line-height: 2rem;
    margin-bottom: 0.25rem;
  }
}
