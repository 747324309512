tui-breadcrumbs {
  a {
    &:first-child {
      span {
        font: var(--tui-font-text-l) !important;
        font-weight: 600 !important;
        color: var(--tui-text-01);
      }
    }

    text-decoration: none !important;

    span {
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
      text-decoration: none !important;
      color: var(--tui-primary);
    }
  }

  tui-svg {
    color: var(--tui-text-04) !important;
  }

  .content {
    max-width: 15rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
  }
}

@media (max-width: $md) {
  tui-breadcrumbs {
    display: none !important;
  }
}
