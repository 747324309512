.df-menu-bg {
  background-color: var(--tui-accent-03) !important;
}

.oxford-menu-bg {
  background-color: var(--tui-oxford-bg) !important;
}

.hide-langauge-switcher .weglot-container {
  display: none;
}
