@import 'config';

.max-w-36 {
  max-width: 9rem;
}

.max-w-3-4 {
  max-width: 75%;
}

.max-w-1-2 {
  max-width: 50%;
}

.max-w-15 {
  max-width: 15rem;
}

.max-w-20 {
  max-width: 20rem;
}

.max-w-90 {
  max-width: 90%;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-16 {
  min-width: 4rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-16 {
  min-height: 4rem;
}

.min-h-96 {
  min-height: 24rem;
}

.break-words {
  word-break: break-word !important;
}

.z-100 {
  z-index: 100;
}
