.team-popover {
  left: -100px !important;
}

.role-control {
  width: 7rem;

  [tuiWrapper]:after {
    display: none !important;
  }

  .value {
    justify-content: flex-end !important;
  }
}

.w-name {
  width: calc(100% - 2.5rem);
}

.text-clamp:not(:hover) {
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
