.tui-tabs-vertical {
  height: auto !important;
  flex-direction: column !important;
  position: relative;
  box-shadow: none !important;

  tui-underline {
    display: none !important;
  }

  .vertical-underline {
    position: absolute;
    width: 0.3rem;
    height: 4rem;
    top: 0px;
    left: 0px;
    z-index: 5;
    background: var(--tui-primary);
    transition: 0.3s var(--tui-cubic-bezier);
  }

  a,
  button {
    height: 4rem;
    padding: 0 1.5rem;
    width: 100%;
    border: 0px !important;

    span {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      text-decoration: none;
      color: var(--tui-text-01);
      font: var(--tui-font-text-l);
      justify-content: flex-start !important;
      width: 100%;
      border: 0px !important;
    }

    &:hover {
      box-shadow: none !important;
      background: var(--tui-base-02);
    }

    svg {
      path {
        fill: var(--tui-text-01);
      }
    }

    &.vertical-active {
      span {
        font-weight: 700;
        color: var(--tui-primary);
      }

      svg {
        path {
          fill: var(--tui-primary);
        }
      }
    }
  }
}
