quill-editor {
  width: 100%;
}

.ql-container {
  background: var(--tui-base-01);
  border: 1px solid var(--tui-base-04);
  border-radius: var(--tui-radius-xs);
  height: 100%;
  padding-top: 2.5rem;
  font: var(--tui-font-text-l) !important;

  @media (max-width: 500px) {
    padding-top: 4rem;
  }

  .ql-blank {
    &:before {
      font: var(--tui-font-text-l);
      color: var(--tui-base-04) !important;
      font-style: normal !important;
      left: 1rem !important;
    }
  }

  .ql-editor {
    padding: 0.75rem 0.5rem;
    max-height: 17.5rem;
    min-height: 15rem;
    overflow-y: auto;

    .mention {
      background-color: unset;
    }

    ul {
      li {
        list-style-type: inherit !important;

        &::before {
          content: none !important;
        }
      }
    }
  }

  .ql-tooltip {
    background: var(--tui-base-01) !important;
    border-radius: var(--tui-radius-xs) !important;
    box-shadow: 0 0.5rem 1rem rgb(51 51 51 / 20%);
    z-index: 10;

    &-arrow {
      border-color: var(--tui-base-01) !important;
    }

    .ql-picker-label {
      color: var(--tui-text-02);

      &:hover {
        color: var(--tui-text-03) !important;
      }
    }

    svg {
      path,
      line,
      rect,
      circle,
      polyline {
        stroke: var(--tui-text-02);
      }

      line {
        fill: var(--tui-text-02);
      }
    }

    .ql-selected,
    .ql-active {
      color: var(--tui-text-04) !important;

      path,
      line,
      rect,
      circle,
      polyline {
        stroke: var(--tui-accent-04) !important;
      }
    }

    button,
    .ql-picker-label,
    .ql-picker-item {
      &:hover,
      &.ql-active {
        color: var(--tui-text-03) !important;

        path,
        line,
        rect,
        circle,
        polyline,
        polygon {
          stroke: var(--tui-text-03) !important;
        }

        line {
          fill: var(--tui-text-03) !important;
        }
      }
    }

    .ql-picker-options {
      background: var(--tui-base-01);
      color: var(--tui-text-02);
      border-radius: var(--tui-radius-s);
      box-shadow: 0 0.5rem 1rem rgb(51 51 51 / 20%);
    }

    input[type='text'] {
      color: var(--tui-text-01) !important;
    }
  }
}

.ql-wrapper {
  position: relative;
  max-height: 20rem;
  border-radius: var(--tui-radius-s) var(--tui-radius-s) 0 0 !important;
}

.ql-toolbar {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 49;
  background: var(--tui-base-01) !important;
  border-radius: var(--tui-radius-s) var(--tui-radius-s) 0 0 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent !important;
}

s {
  text-decoration: line-through;
}

i,
em {
  font-style: italic;
}

.preview-ql-editor {
  font: var(--tui-font-text-l);
  color: var(--tui-text-02);

  span {
    font: var(--tui-font-text-l);
    color: var(--tui-text-02);
  }

  .ql-size-large {
    font: var(--tui-font-heading-5);
  }

  .ql-size-huge {
    font: var(--tui-font-heading-3);
  }

  .ql-size-small {
    font: var(--tui-font-text-s);
  }

  a {
    color: var(--tui-primary);
  }

  .ql-indent-1 {
    padding-left: 3em;
  }

  .ql-indent-2 {
    padding-left: 6em;
  }

  .ql-indent-3 {
    padding-left: 9em;
  }

  ol,
  ul {
    padding-left: 1.5em;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  ol {
    li {
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-0;
      list-style-type: none;

      &:before {
        content: counter(list-0, decimal) '. ';
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }

      &.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-1;

        &:before {
          content: counter(list-1, lower-alpha) '. ';
        }
      }

      &.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-2;

        &:before {
          content: counter(list-2, lower-roman) '. ';
        }
      }

      &.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-3;

        &:before {
          content: counter(list-3, lower-decimal) '. ';
        }
      }
    }
  }

  iframe {
    max-width: 100%;
  }

  img {
    max-width: 90%;
    max-height: 90vh;
  }
}
