.country-selector {
  right: 0 !important;
  width: 6rem !important;

  .wgcurrent {
    border: 1px solid var(--tui-text-04) !important;

    &:after {
      display: none !important;
    }
  }

  &.closed .wgcurrent {
    border-top-left-radius: 0.25rem !important;
  }

  a {
    padding: 0.5rem !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    line-height: 0.875rem !important;
    color: var(--tui-text-03) !important;
    height: auto !important;

    .wg-flag {
      margin-right: 0.375rem !important;
      height: 1rem !important;
      border-radius: 0.25rem !important;
      width: auto;
    }
  }

  &.weg-openup ul {
    bottom: 100% !important;
    border-color: var(--tui-base-04) !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-color: transparent !important;

    & > li:hover {
      background-color: #fafafa !important;
    }
  }
}
