tui-checkbox-labeled,
tui-checkbox {
  .content {
    color: var(--tui-text-03);
  }

  [tuiWrapper] {
    &[data-appearance='primary'] {
      border-radius: 0.25rem;
      background: var(--tui-primary);
      color: var(--tui-primary-text);
    }

    &._invalid {
      &:after {
        color: var(--tui-accent-01) !important;
      }
    }

    &[data-appearance='outline'] {
      &:after {
        transition: color var(--tui-duration) ease-in-out;
        border-width: 1px;
        color: var(--tui-base-04);
        border-radius: 0.25rem;
      }

      &[data-state='hovered'] {
        &:after {
          color: var(--tui-text-05);
        }
      }
    }

    &[data-state='disabled'] {
      opacity: 0.4 !important;
    }
  }
}

tui-checkbox-block {
  &[data-size='l'] {
    [tuiWrapper] {
      .view {
        margin-top: 0.75rem !important;
      }
    }
  }

  [tuiWrapper] {
    &:after {
      color: var(--tui-base-02) !important;
    }
  }
}
