tui-tooltip {
  width: 1rem !important;
  height: 1rem !important;

  svg,
  use {
    color: var(--tui-accent-09);
    width: 1rem !important;
    height: 1rem !important;
  }
}

tui-hint {
  background-color: var(--tui-hint-background) !important;
  color: var(--tui-hint-text) !important;
}
