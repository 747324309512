div[data-appearance='textfield'][tuiwrapper] {
  box-shadow: none !important;
  border-radius: var(--tui-radius-xs);
  background: var(--tui-base-01);

  .t-placeholder {
    color: var(--tui-text-04) !important;
  }

  &:after {
    transition: color var(--tui-duration) ease-in-out;
    border-width: 1px;
    color: var(--tui-base-04);
  }

  &._invalid {
    &:after {
      color: var(--tui-accent-01);
    }
  }

  &._focused {
    &:after {
      color: var(--tui-primary);
      border-width: 1px;
    }
  }

  .cleaner,
  .t-icon {
    color: var(--tui-base-04);

    &:hover {
      color: var(--tui-primary);
    }
  }

  input {
    font: var(--tui-font-text-l) !important;
  }
}
