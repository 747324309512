@import 'config';

tui-dialog {
  > .t-content {
    .t-heading {
      text-align: center;
      margin-top: 1rem;
    }
  }

  &[data-size='s'] {
    width: 40vw !important;
  }

  &[data-size='m'] {
    width: 55rem !important;

    > .t-content {
      padding: 2rem 2rem 3rem 2rem;

      .t-heading {
        margin-bottom: 2rem !important;
      }
    }
  }

  &[data-size='l'] {
    width: 80vw !important;
  }

  &[data-size='fullscreen'] {
    > .t-content {
      padding: 1rem !important;
    }
  }

  &.full-size {
    width: 100vw !important;
    border: 0px !important;
    border-radius: 0px !important;
  }

  .close {
    position: absolute !important;
    top: 1rem !important;
    right: 1rem !important;
  }
}

tui-dialog-host {
  > .overlay {
    backdrop-filter: blur(3px);
  }
}

@media (max-width: $xl) {
  tui-dialog {
    &[data-size='s'] {
      width: 50vw !important;
    }
  }
}

@media (max-width: $lg) {
  tui-dialog {
    &[data-size='s'] {
      width: 80vw !important;
    }
  }
}

@media (max-width: $md) {
  tui-dialog {
    > .t-content {
      .t-heading {
        max-width: 80vw;
        margin: 0 auto;
      }
    }
  }
}
