.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow:
    0px 2px 6px rgb(207 215 224 / 79%),
    0px 1px 1px #d3d9de;
  border-radius: var(--tui-radius-s);
  padding: 0.75rem;

  &-title {
    font: var(--tui-font-text-m);
    font-weight: bold;
    color: var(--tui-text-03);
    margin-bottom: 0.5rem;
  }

  .confirm-btns {
    margin-top: 1rem;

    button {
      border-radius: var(--tui-radius-s);
      padding: 0.75rem 1.5rem;
      background: var(--tui-base-01);
      border: 1px solid var(--tui-base-04);

      &.btn-danger {
        background: var(--tui-accent);
        color: var(--tui-base-01);
        border-color: var(--tui-accent);
      }

      &.btn-success {
        background: var(--tui-accent-08);
      }
    }
  }
}
