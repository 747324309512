.tui-base-01 {
  color: var(--tui-base-01);

  svg {
    path {
      fill: var(--tui-base-01);
    }
  }

  &-bg {
    background-color: var(--tui-base-01) !important;
  }
}

.tui-base-09 {
  color: var(--tui-base-09);

  svg {
    path {
      fill: var(--tui-base-09);
    }
  }

  &-bg {
    background-color: var(--tui-base-09) !important;
  }
}

.tui-base-10 {
  color: var(--tui-base-10);

  svg {
    path {
      fill: var(--tui-base-10);
    }
  }

  &-bg {
    background-color: var(--tui-base-10) !important;
  }
}

.tui-text-01 {
  color: var(--tui-text-01);

  svg {
    path,
    circle {
      fill: var(--tui-text-01);
    }
  }

  &-bg {
    background-color: var(--tui-text-01) !important;
  }
}

.tui-text-02 {
  color: var(--tui-text-02);
}

.tui-text-03 {
  color: var(--tui-text-03);

  svg {
    path {
      fill: var(--tui-text-03) !important;
    }
  }

  &-bg {
    background-color: var(--tui-text-03) !important;
  }
}

.tui-text-04 {
  color: var(--tui-text-04);

  svg {
    path {
      fill: var(--tui-text-04) !important;
    }
  }

  &-bg {
    background-color: var(--tui-text-04) !important;
  }

  &-border {
    border-color: var(--tui-text-04) !important;
  }

  &-border-hvr:hover {
    border-color: var(--tui-text-04) !important;
  }
}

.tui-text-05 {
  color: var(--tui-text-05);
}

.tui-base-02 {
  background: var(--tui-base-02);

  &-border {
    border-color: var(--tui-base-02) !important;
  }
}

.tui-base-03 {
  background: var(--tui-base-03);

  &-border {
    border-color: var(--tui-base-03) !important;
  }
}

.tui-base-04 {
  background: var(--tui-base-04);

  &-border {
    border-color: var(--tui-base-04) !important;
  }

  &-svg {
    svg {
      path {
        fill: var(--tui-base-04) !important;
      }
    }
  }
}

.tui-base-05 {
  background: var(--tui-base-05);

  &-border {
    border-color: var(--tui-base-05) !important;
  }

  &-svg {
    svg {
      path {
        fill: var(--tui-base-05) !important;
      }
    }
  }
}

.tui-base-06 {
  color: var(--tui-base-06);

  &-bg {
    background-color: var(--tui-base-06);
  }

  &-border {
    border-color: var(--tui-base-06);
  }

  svg {
    path {
      fill: var(--tui-base-06) !important;
    }
  }
}

.tui-primary {
  color: var(--tui-primary) !important;

  &-bg {
    background-color: var(--tui-primary) !important;
  }

  &-border {
    border-color: var(--tui-primary) !important;
  }

  svg {
    path {
      fill: var(--tui-primary) !important;
    }
  }
}

.tui-secondary {
  color: var(--tui-secondary) !important;

  &-bg {
    background-color: var(--tui-secondary) !important;
  }

  &-border {
    border-color: var(--tui-secondary) !important;
  }

  svg {
    path {
      fill: var(--tui-p--tui-secondaryrimary) !important;
    }
  }
}

.tui-accent {
  color: var(--tui-accent) !important;

  svg {
    path {
      fill: var(--tui-accent) !important;
    }
  }
}

.tui-accent-01 {
  color: var(--tui-accent-01) !important;

  svg {
    path {
      fill: var(--tui-accent-01) !important;
    }
  }

  [tuiWrapper] {
    .content {
      color: var(--tui-accent-01) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-01) !important;
  }
}

.tui-accent-02 {
  color: var(--tui-accent-02) !important;

  &-bg {
    background-color: var(--tui-accent-02) !important;
  }

  &-border {
    border-color: var(--tui-accent-02) !important;
  }
}

.tui-accent-03 {
  color: var(--tui-accent-03) !important;

  &-bg {
    background-color: var(--tui-accent-03) !important;
  }
}

.tui-accent-04 {
  color: var(--tui-accent-04) !important;

  &-bg {
    background-color: var(--tui-accent-04) !important;
  }
}

.tui-accent-05 {
  color: var(--tui-accent-05) !important;

  svg {
    path {
      fill: var(--tui-accent-05) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-05) !important;
  }
}

.tui-accent-07 {
  color: var(--tui-accent-07);

  &-bg {
    background-color: var(--tui-accent-07);
  }

  &-border {
    border-color: var(--tui-accent-07);
  }

  svg {
    path {
      fill: var(--tui-accent-07) !important;
    }
  }
}

.tui-accent-08 {
  background-color: var(--tui-accent-08) !important;
}

.tui-accent-09 {
  color: var(--tui-accent-09);

  svg {
    path {
      fill: var(--tui-accent-09) !important;
    }
  }
}

.tui-accent-10 {
  color: var(--tui-accent-10);

  svg {
    path {
      fill: var(--tui-accent-10) !important;
    }
  }
}

.tui-accent-11 {
  color: var(--tui-accent-11);

  svg {
    path {
      fill: var(--tui-accent-11) !important;
    }
  }
}

.tui-accent-12 {
  color: var(--tui-accent-12);

  svg {
    path {
      fill: var(--tui-accent-12) !important;
    }
  }
}

.tui-accent-13 {
  color: var(--tui-accent-13);

  svg {
    path {
      fill: var(--tui-accent-13) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-13) !important;
  }

  &-border {
    border-color: var(--tui-accent-13) !important;
  }
}

.tui-accent-14 {
  color: var(--tui-accent-14);

  svg {
    path {
      fill: var(--tui-accent-14) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-14);
  }
}

.tui-accent-15 {
  color: var(--tui-accent-15);

  svg {
    path {
      fill: var(--tui-accent-15) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-15);
  }

  &-border {
    border-color: var(--tui-accent-15) !important;
  }
}

.tui-accent-16 {
  color: var(--tui-accent-16);

  svg {
    path {
      fill: var(--tui-accent-16) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-16);
  }

  &-border {
    border-color: var(--tui-accent-16) !important;
  }
}

.tui-accent-17 {
  color: var(--tui-accent-17);

  svg {
    path {
      fill: var(--tui-accent-17) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-17);
  }

  &-border {
    border-color: var(--tui-accent-17) !important;
  }
}

.tui-accent-18 {
  color: var(--tui-accent-18);

  svg {
    path {
      fill: var(--tui-accent-18) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-18);
  }

  &-border {
    border-color: var(--tui-accent-18) !important;
  }
}

.tui-accent-19 {
  color: var(--tui-accent-19);

  svg {
    color: var(--tui-accent-19);

    path {
      fill: var(--tui-accent-19) !important;
    }
  }

  &-bg {
    background-color: var(--tui-accent-19);
  }

  &-border {
    border-color: var(--tui-accent-19) !important;
  }
}

.tui-link-night-hover {
  color: var(--tui-link-night-hover);

  svg {
    color: var(--tui-link-night-hover);

    path {
      fill: var(--tui-link-night-hover) !important;
    }
  }

  &-bg {
    background-color: var(--tui-link-night-hover);
  }

  &-border {
    border-color: var(--tui-link-night-hover) !important;
  }
}

.tui-support-25 {
  &-bg {
    background: var(--tui-support-25);
  }
}

.tui-support-26 {
  &-bg {
    background: var(--tui-support-26);
  }
}

.tui-support-27 {
  &-bg {
    background: var(--tui-support-27);
  }

  &-border {
    border-color: var(--tui-support-27);
  }
}

.tui-gray-01 {
  color: var(--tui-gray-01);

  svg {
    path {
      fill: var(--tui-gray-01) !important;
    }
  }

  &-bg {
    background-color: var(--tui-gray-01);
  }

  &-border {
    border-color: var(--tui-gray-01) !important;
  }
}

.icebreaker {
  background-color: var(--tui-accent-01);

  &-text {
    color: var(--tui-accent-01);
  }

  &-border {
    border-color: var(--tui-accent-01);
  }

  &-faded {
    background-color: var(--tui-accent-01-faded);
  }

  &-light {
    background-color: var(--tui-accent-01-light);
  }

  &-lighter {
    background-color: var(--tui-accent-01-lighter);
  }
}

.cheeseburger {
  background-color: var(--tui-accent-07);

  &-text {
    color: var(--tui-accent-07);

    &-light {
      color: var(--tui-accent-07-light);
    }
  }

  &-border {
    border-color: var(--tui-accent-07);
  }

  &-faded {
    background-color: var(--tui-accent-07-faded);
  }

  &-light {
    background-color: var(--tui-accent-07-light);
  }

  &-lighter {
    background-color: var(--tui-accent-07-lighter);
  }
}

.main-product {
  background-color: var(--tui-accent-05);

  &-text {
    color: var(--tui-accent-05);
  }

  &-border {
    border-color: var(--tui-accent-05);
  }

  &-faded {
    background-color: var(--tui-accent-05-faded);
  }

  &-light {
    background-color: var(--tui-accent-05-light);
  }

  &-lighter {
    background-color: var(--tui-accent-05-lighter);
  }
}

.upsell {
  background-color: var(--tui-accent);

  &-text {
    color: var(--tui-accent);
  }

  &-border {
    border-color: var(--tui-accent);
  }

  &-faded {
    background-color: var(--tui-accent-faded);
  }

  &-light {
    background-color: var(--tui-accent-light);
  }

  &-lighter {
    background-color: var(--tui-accent-lighter);
  }
}

.black-80 {
  color: var(--black-80);

  svg {
    path {
      fill: var(--black-80);
    }
  }

  &-bg {
    background-color: var(--black-80) !important;
  }
}
