a[tuiLink],
a {
  text-decoration: underline;
  font: var(--tui-font-text-m);

  span {
    text-decoration: underline;
    font: var(--tui-font-text-m);
  }
}
