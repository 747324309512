tui-input-files {
  .t-wrapper {
    &:after {
      color: var(--tui-primary) !important;
    }

    a[tuiLink] {
      color: var(--tui-primary) !important;
    }
  }
}
