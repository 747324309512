@import 'config';

tui-island {
  &.tui-island_size_m {
    padding: 2rem 3.5rem !important;
  }
}

@media (max-width: $md) {
  tui-island {
    &.tui-island_size_m {
      padding: 1rem !important;
    }
  }
}
