tui-multi-select {
  [data-size='m'] {
    :not(._label-outside) {
      .tags {
        padding-top: 1.25rem !important;
        height: 3rem;
      }
    }

    height: 3rem !important;
    min-height: 3rem !important;
    max-height: 3rem !important;
    font: var(--tui-font-text-m) !important;

    .t-placeholder {
      font: var(--tui-font-text-m);
      height: 1.5rem;
      transform: translateY(0.2rem);
    }

    .absolute-wrapper,
    .icons {
      height: 3rem !important;
    }

    [tuiWrapper] > .content {
      padding-left: 1rem !important;
    }

    tui-hosted-dropdown {
      height: inherit;
    }
  }
}
