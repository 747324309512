tui-alert-host {
  z-index: 999999;

  .t-wrapper {
    margin: auto;
  }

  tui-alert {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
