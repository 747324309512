tui-radio-labeled {
  > label {
    display: inline-flex;
    > tui-radio {
      width: 1.5rem;
      height: 1.5rem;

      > [tuiWrapper] {
        border: 1px solid var(--tui-base-04);
        border-radius: 100%;
      }

      > [tuiWrapper][data-appearance='primary'] {
        background: var(--tui-primary);
        color: var(--tui-primary-text);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
